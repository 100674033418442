import React from 'react';

const GreenMortgagesFAQ = () => ([
    {
      title: 'What are green mortgages?',
      description: (
        <>
        Green mortgages are type of mortgage offered by Banks that reward borrowers for taking out a mortgage based on the Energy Performance Certificate rating on their home and encourage homeowners to make more energy-efficient choices on their home.
        </>
      ),
    },
    {
      title: 'How do green mortgages work?',
      description: (
        <>
          Lenders assess the potential energy savings resulting from any improvements and they are then factored into the mortgage application process.<br/><br/>
          After the assessment, if a borrower has a valid Energy Performance Certificate (EPC) then they could get access to lower interest rates.<br /><br />
          Green home improvements include installing solar panels, upgrading insulation, using energy-efficient appliances, and implementing other sustainable features that reduce a home's environmental impact and help combat climate change.
        </>
      ),
    },
    {
      title: 'What is an energy performance certificate EPC)?',
      description: (
        <>
          An Energy Performance Certificate (EPC) that provides the energy efficiency rating of a building or home.<br/><br/>
          An ERC is designed to help homeowners, and potential buyers understand the energy performance of a property and make informed decisions about its environmental impact.
        </>
      ),
    },
    {
      title: 'What are the benefits of a green mortgage?',
      description: (
        <>
          Green mortgages are become more popular in recent years.<br/><br/>
          There are several benefits associated with a green mortgage. With an energy-efficient property, homeowners can lower their energy bills, which can offset the additional mortgage costs.<br /><br />
          Green mortgages can help with climate change, reducing carbon footprints and promoting sustainability.<br /><br />
          Lastly, by investing in energy-efficient upgrades, homeowners may increase the overall value of their property.
        </>
      ),
    },
    {
      title: 'Do all mortgage lenders offer green mortgages?',
      description: (
        <>
          As green mortgages are fairly a new type of mortgage, not all mortgage lenders are offering a green mortgage product just yet.<br/><br/>
          Most of the larger lenders are now starting to offer green mortgages but it's best to speak to a mortgage broker to find out your options if you're considering looking for a green mortgage deal.
        </>
      ),
    },
    {
      title: 'What makes an energy-efficient home?',
      description: (
        <>
          A green mortgage is for energy-efficient properties. Energy-efficient homes aim to reduce energy consumption, lower utility bills, and decrease the overall environmental impact.<br/><br/>
          After being assessed, a valid energy performance certificate will be granted to the borrower or homeowner to pass the home as an energy-efficient property.
        </>
      ),
    },
    {
      title: 'Can green mortgages save money?',
      description: (
        <>
          Yes, green mortgages can potentially save you money in the long run.<br/><br/>
          By making green home improvements you can save money on lower energy bills which add up over the course of your mortgage.<br /><br />
          By making energy-efficient home improvements you can also improve the property value to give you greater returns on your investment.
        </>
      ),
    },
    {
      title: 'What are green properties to consider when looking at the property\'s energy efficiency?',
      description: (
        <>
          There are a few features that you can consider when looking to improve your mortgage energy efficiency when looking to obtain your energy performance certificate rating.<br/><br/>
          This includes having energy-efficient insulation, double-glazed windows, energy-efficient appliances and lighting and so on.
        </>
      ),
    },
  ]);

export default GreenMortgagesFAQ;
